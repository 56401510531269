<template>
  <div class="pb-20 pt-20">
        <div class="container mx-auto grid grid-cols-1 p-3 sm:w-full md:w-4/12">
 
            <div v-if="campaigns.length > 0">
                <div class="mt-5 grid grid-cols-4 gap-4" v-for="campaign in campaigns" :key="campaign.id">
                    <div class="col-span-4">
                        <CampaignLargeItem 
                            :image="campaign.image" 
                            :title="campaign.title" 
                            :slug="campaign.slug" 
                            :sumDonation="campaign.sum_donation"
                            :targetDonation="campaign.target_donation"
                            :maxDate="campaign.max_date" /> 
                    </div>
                </div>
            </div>
            <div v-else>

                <div v-for="index in 2" :key="index" class="grid grid-cols-1 bg-white rounded shadow-md p-3 text-sm mt-4 mb-4">
                    <FacebookLoader class="h-24"/>
                </div>

            </div>

        </div>

        <div class="text-center mt-4 mb-4" v-show="nextExists">
            <a @click="loadMore"
                class="bg-gray-700 text-white p-2 px-3 rounded-md shadow-md focus:outline-none focus:bg-gray-900 cursor-pointer">LIHAT
                SEMUA <i class="fa fa-long-arrow-alt-right"></i></a>
        </div>

    </div>
</template>

<script>

    //hook vue
    import { computed, onMounted } from 'vue'

    //vuex
    import { useStore } from 'vuex'

    //vue content loader
    import { FacebookLoader } from 'vue-content-loader'

    import CampaignLargeItem from '@/components/CampaignLargeItem.vue'

    export default {

        components: {
            FacebookLoader,
            CampaignLargeItem  // <-- register component FacebooLoader dari Vue Content Loader
        },

        setup() {

            //store vuex
            const store = useStore()

            //onMounted akan menjalankan action "getCampaign" di module "campaign"
            onMounted(() => {
                store.dispatch('campaign/getCampaign')
            })

            //digunakan untuk get data  state "campaigns" di module "campaign" 
            const campaigns = computed(() => {
                return store.state.campaign.campaigns
            })

            //get status NextExists
            const nextExists = computed(() => {
                return store.state.campaign.nextExists
            })

            //get nextPage
            const nextPage = computed(() => {
                return store.state.campaign.nextPage
            })

            //loadMore function
            function loadMore() {
                store.dispatch('campaign/getLoadMore', nextPage.value)
            }    

            return {
                campaigns,      // <-- return campaigns
                nextExists,     // <-- return nextExists,
                nextPage,       // <-- return nextPage
                loadMore,        // <-- return loadMore
            }

        }

    }
</script>

<style>

</style>